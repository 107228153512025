import React from "react"
import { graphql } from "gatsby"
import Header from "../components/header/header"
import SEO from "../components/seo"
import NewsSectionHeader from "../components/home/newsSectionHeader"
import NewsCategories from "../components/home/newsCategories"
import NewsColumn from "../components/home/newsColumn"
import Footer from "../components/footer/Footer"
export const query = graphql`
  query($slug: String!) {
    allWpPost(
      filter: { categories: { nodes: { elemMatch: { slug: { eq: $slug } } } } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          title
          slug
          date(formatString: "MM . DD . YYYY")
          content
          excerpt
          uri
          categories {
            nodes {
              description
            }
          }
        }
      }
    }
  }
`

const CategoryTemplate = ({ data, pageContext }) => {
  return (
    <>
      <Header />
      <SEO title="Category" />
      <section className="news-section news-page">
        <div className="container">
          <NewsSectionHeader
            title={pageContext.categoryName}
            text={
              data.allWpPost.edges[0].node.categories.nodes[0].description
                ? data.allWpPost.edges[0].node.categories.nodes[0].description
                : ""
            }
          ></NewsSectionHeader>
          <NewsCategories></NewsCategories>

          <div className="category__posts">
            {data.allWpPost.edges.map((post, i) => (
              <NewsColumn
                key={i}
                title={post.node.title}
                excerpt={post.node.excerpt}
                slug={post.node.uri}
              >
                {" "}
              </NewsColumn>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default CategoryTemplate
